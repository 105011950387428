import { Inject, Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { CountriesConfig } from "@premium-portal/types";
import { COUNTRIES_TOKEN } from "./countries.token";
import { CountryStatus } from "./country-status.enum";
import { CountryDto } from "./country-roll-out.model";

@Injectable()
export class CountriesService {
  private static readonly ENDPOINT = "premium-portal";

  private readonly baseUrl: string;

  constructor(private http: HttpClient, @Inject(COUNTRIES_TOKEN) private config: CountriesConfig) {
    this.baseUrl = config.shouldUseCookieAuthentication ? config.environment.gatewayUrl.toString() : config.environment.apiUrl.toString();
  }

  public isCountryRolledOut(): Observable<boolean> {
    return this.http.get<CountryDto>(`${this.baseUrl}${CountriesService.ENDPOINT}/user/country`).pipe(
      map((response) => {
        return response.status === CountryStatus.AVAILABLE;
      })
    );
  }
}
