import { EnvironmentInjector, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, concat, first, last, takeWhile, of } from "rxjs";
import { concatMap } from "rxjs/operators";

export const runSerially = (guards: CanActivateFn[]): CanActivateFn => {
  return (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const injector = inject(EnvironmentInjector);
    const observables = guards.map((guard) => {
      const guardResult = injector.runInContext(() => guard(route, state)) as Observable<boolean | UrlTree>;
      return guardResult.pipe(first());
    });

    let hasARedirection: UrlTree | null = null;
    return concat(...observables).pipe(
      concatMap((result: boolean | UrlTree) => {
        if (result instanceof UrlTree) {
          hasARedirection = result;
          return of(result);
        }
        return of(result);
      }),
      takeWhile(() => !hasARedirection, true),
      last()
    );
  };
};
