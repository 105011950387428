import { CanActivateFn, Router, UrlTree } from "@angular/router";
import { inject } from "@angular/core";
import { ComplianceService } from "./compliance.service";
import { map, Observable } from "rxjs";
import { COMPLIANCE_TOKEN } from "./compliance.token";
import { ComplianceStatus, ComplianceType } from "./compliance.model";

/**
 * Angular guard that requires the DAMEX SPS check to be valid for a user
 */
export const damexComplianceGuard: CanActivateFn = (): Observable<UrlTree | boolean> => {
  const userComplianceService = inject(ComplianceService);
  const router = inject(Router);
  const config = inject(COMPLIANCE_TOKEN);

  return userComplianceService.getUserComplianceStatus(ComplianceType.DAMEXSPS).pipe(
    map((status: ComplianceStatus): UrlTree | boolean => {
      // should it run the context where the pages are available in the current router?
      if (config.shouldUseLocalRouter) {
        switch (status) {
          case ComplianceStatus.INVALID:
            return router.createUrlTree(["/blocked"]);
          case ComplianceStatus.PENDING:
            return router.createUrlTree(["/blocked/pending"]);
          case ComplianceStatus.MISSING_USER_FIRST_NAME:
            return router.createUrlTree(["/blocked/missing-information", { queryParams: { status } }]);
          case ComplianceStatus.MISSING_USER_LAST_NAME:
            return router.createUrlTree(["/blocked/missing-information", { queryParams: { status } }]);
          case ComplianceStatus.MISSING_USER_COMPANY:
            return router.createUrlTree(["/blocked/missing-information", { queryParams: { status } }]);
          case ComplianceStatus.MISSING_USER_COUNTRY:
            return router.createUrlTree(["/blocked/missing-information", { queryParams: { status } }]);
          case ComplianceStatus.MISSING_USER_CITY:
            return router.createUrlTree(["/blocked/missing-information", { queryParams: { status } }]);
          case ComplianceStatus.MISSING_USER_STREET:
            return router.createUrlTree(["/blocked/missing-information", { queryParams: { status } }]);
        }

        return true;
      }

      // when we get any status other than valid, we just redirect the user's browser to the base IPP url (which should be start page)
      // to then handle the full flow
      if (status !== ComplianceStatus.VALID) {
        window.location.href = config.environment.url.toString();
        return false;
      }
      return true;
    })
  );
};
