export enum LibRoutes {
  ABOUT_LEGAL_INFO = "/about-legal-info",
  CONTACT = "/contacts",
  CONTRACTS = "/contracts",
  ROOT = "/",
  DASHBOARD = "/dashboard",
  GENERAL_SETTINGS = "/general-settings",
  SECURITY = "/security",
  USER_PREFERENCES = "/user-preferences",
  USE_POLICY = "/use-policy",
  WELCOME_BASIC_SERVICES = "/welcome/basic-services",
  COUNTRY_NOT_ROLL_OUT = "error/not-rolled-out",
  NOTIFICATIONS_PAGE = "notifications",
}
