import { CanActivateFn, Router } from "@angular/router";
import { inject } from "@angular/core";
import { first, map } from "rxjs";
import { LibRoutes } from "@premium-portal/types";
import { CountriesService } from "./countries.service";
import { COUNTRIES_TOKEN } from "./countries.token";

export const countryRollOutGuard: CanActivateFn = () => {
  const countryService = inject(CountriesService);
  const router = inject(Router);
  const countryRollOutConfig = inject(COUNTRIES_TOKEN);

  return countryService.isCountryRolledOut().pipe(
    first(),
    map((countryRolledOut) => {
      // if country is already rolled out
      if (countryRolledOut) {
        return true;
      }

      const { shouldUseLocalRouter, environment } = countryRollOutConfig;

      if (shouldUseLocalRouter) {
        router.navigate([LibRoutes.COUNTRY_NOT_ROLL_OUT]);
      } else {
        const fullUrl = `${environment.url}${LibRoutes.COUNTRY_NOT_ROLL_OUT}`;
        window.location.href = fullUrl;
      }

      return false;
    })
  );
};
